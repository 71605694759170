<template>
  <v-menu nudge-top="-10" offset-y>
    <template v-slot:activator="{ props }">
      <v-btn
        :class="{
          'text-primary': colorMode.preference === colorMode.value,
        }"
        aria-label="colorMode"
        v-bind="props"
        icon
        variant="text"
      >
        <svg
          v-show="colorMode.value === 'light'"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-sun"
          data-v-2b778c3e=""
        >
          <circle cx="12" cy="12" r="5" data-v-2b778c3e=""></circle>
          <line x1="12" y1="1" x2="12" y2="3" data-v-2b778c3e=""></line>
          <line x1="12" y1="21" x2="12" y2="23" data-v-2b778c3e=""></line>
          <line
            x1="4.22"
            y1="4.22"
            x2="5.64"
            y2="5.64"
            data-v-2b778c3e=""
          ></line>
          <line
            x1="18.36"
            y1="18.36"
            x2="19.78"
            y2="19.78"
            data-v-2b778c3e=""
          ></line>
          <line x1="1" y1="12" x2="3" y2="12" data-v-2b778c3e=""></line>
          <line x1="21" y1="12" x2="23" y2="12" data-v-2b778c3e=""></line>
          <line
            x1="4.22"
            y1="19.78"
            x2="5.64"
            y2="18.36"
            data-v-2b778c3e=""
          ></line>
          <line
            x1="18.36"
            y1="5.64"
            x2="19.78"
            y2="4.22"
            data-v-2b778c3e=""
          ></line>
        </svg>
        <svg
          v-show="colorMode.value === 'dark'"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-moon selected"
          data-v-2b778c3e=""
        >
          <path
            d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"
            data-v-2b778c3e=""
          ></path>
        </svg>
      </v-btn>
    </template>
    <client-only>
      <v-list class="pa-0 ma-0">
        <v-item-group v-model="colorMode.preference" mandatory color="primary">
          <v-item v-slot="{ isSelected, toggle }" value="light">
            <v-list-item
              :active="isSelected"
              :title="$t('ColorModeBtn.light')"
              @click="toggle"
            >
              <template v-slot:prepend>
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-sun mr-4"
                  data-v-2b778c3e=""
                >
                  <circle cx="12" cy="12" r="5" data-v-2b778c3e=""></circle>
                  <line x1="12" y1="1" x2="12" y2="3" data-v-2b778c3e=""></line>
                  <line
                    x1="12"
                    y1="21"
                    x2="12"
                    y2="23"
                    data-v-2b778c3e=""
                  ></line>
                  <line
                    x1="4.22"
                    y1="4.22"
                    x2="5.64"
                    y2="5.64"
                    data-v-2b778c3e=""
                  ></line>
                  <line
                    x1="18.36"
                    y1="18.36"
                    x2="19.78"
                    y2="19.78"
                    data-v-2b778c3e=""
                  ></line>
                  <line x1="1" y1="12" x2="3" y2="12" data-v-2b778c3e=""></line>
                  <line
                    x1="21"
                    y1="12"
                    x2="23"
                    y2="12"
                    data-v-2b778c3e=""
                  ></line>
                  <line
                    x1="4.22"
                    y1="19.78"
                    x2="5.64"
                    y2="18.36"
                    data-v-2b778c3e=""
                  ></line>
                  <line
                    x1="18.36"
                    y1="5.64"
                    x2="19.78"
                    y2="4.22"
                    data-v-2b778c3e=""
                  ></line>
                </svg>
              </template>
            </v-list-item>
          </v-item>
          <v-item v-slot="{ isSelected, toggle }" value="dark">
            <v-list-item
              :active="isSelected"
              :title="$t('ColorModeBtn.dark')"
              @click="toggle"
            >
              <template v-slot:prepend>
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-moon selected mr-4"
                  data-v-2b778c3e=""
                >
                  <path
                    d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"
                    data-v-2b778c3e=""
                  ></path>
                </svg>
              </template>
            </v-list-item>
          </v-item>
          <v-item v-slot="{ isSelected, toggle }" value="system">
            <v-list-item
              :active="isSelected"
              :title="$t('ColorModeBtn.system')"
              @click="toggle"
            >
              <template v-slot:prepend>
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  data-v-2b778c3e=""
                  class="feather feather-monitor preferred mr-4"
                >
                  <rect
                    x="2"
                    y="3"
                    width="20"
                    height="14"
                    rx="2"
                    ry="2"
                    data-v-2b778c3e=""
                  ></rect>
                  <line
                    x1="8"
                    y1="21"
                    x2="16"
                    y2="21"
                    data-v-2b778c3e=""
                  ></line>
                  <line
                    x1="12"
                    y1="17"
                    x2="12"
                    y2="21"
                    data-v-2b778c3e=""
                  ></line>
                </svg>
              </template>
            </v-list-item>
          </v-item>
        </v-item-group>
      </v-list>
    </client-only>
  </v-menu>
</template>

<script setup>
const colorMode = useColorMode()
</script>
