<template>
  <v-btn
    v-show="
      route.name !== 'user-subscription' &&
      store.user &&
      store.user.plan === 'free'
    "
    :to="{ name: 'user-subscription' }"
  >
    Get started today
  </v-btn>
</template>

<script setup>
import { useStore } from '@/composables/store'
const store = useStore()
const route = useRoute()
</script>
