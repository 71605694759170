import type { ModuleOptions } from '#cookie-control/types'

export default {
  "barPosition": "bottom-full",
  "closeModalOnClickOutside": false,
  "colors": {
    "barBackground": "#141028",
    "barButtonBackground": "#fc619d",
    "barButtonColor": "#fff",
    "barButtonHoverBackground": "#fc619d",
    "barButtonHoverColor": "#fff",
    "barTextColor": "#fff",
    "checkboxActiveBackground": "#BDBDBD",
    "checkboxActiveCircleBackground": "#fc619d",
    "checkboxDisabledBackground": "#EEEEEE",
    "checkboxDisabledCircleBackground": "#fff",
    "checkboxInactiveBackground": "#BDBDBD",
    "checkboxInactiveCircleBackground": "#fff",
    "controlButtonBackground": "#fff",
    "controlButtonHoverBackground": "#000",
    "controlButtonIconColor": "#000",
    "controlButtonIconHoverColor": "#fff",
    "focusRingColor": "#808080",
    "modalBackground": "#141028",
    "modalButtonBackground": "#fc619d",
    "modalButtonColor": "#fff",
    "modalButtonHoverBackground": "#fc619d",
    "modalButtonHoverColor": "#fff",
    "modalOverlay": "#000",
    "modalOverlayOpacity": 0.8,
    "modalTextColor": "#fff",
    "modalUnsavedColor": "#fff"
  },
  "cookies": {
    "necessary": [
      {
        "name": {
          "en": "Default Cookies"
        },
        "description": {
          "en": "Used for cookie control."
        },
        "targetCookieIds": [
          "cookie_control_consent",
          "cookie_control_enabled_cookies"
        ]
      },
      {
        "name": {
          "en": "Color mode cookies"
        },
        "description": {
          "en": "Used to control color mode."
        },
        "targetCookieIds": [
          "color-mode-preference",
          "color-mode",
          "nuxt-color-mode"
        ]
      },
      {
        "name": {
          "en": "Session cookies"
        },
        "description": {
          "en": "Used to control sign in and sign up."
        },
        "targetCookieIds": [
          "__session"
        ]
      }
    ],
    "optional": [
      {
        "name": {
          "en": "Google Analytics"
        },
        "description": {
          "en": "Google Analytics is a web analytics service offered by Google that tracks and reports website traffic."
        },
        "src": "https://www.googletagmanager.com/gtm.js?id=GTM-M56QLVH",
        "targetCookieIds": [
          "_ga",
          "_ga_B5JWY96J29",
          "_gid",
          "_gat"
        ]
      }
    ]
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "ncc_c",
  "cookieNameCookiesEnabledIds": "ncc_e",
  "cookieOptions": {
    "path": "/"
  },
  "isAcceptNecessaryButtonEnabled": true,
  "isControlButtonEnabled": false,
  "isCookieIdVisible": false,
  "isCssEnabled": true,
  "isCssPonyfillEnabled": false,
  "isDashInDescriptionEnabled": true,
  "isIframeBlocked": false,
  "isModalForced": false,
  "locales": [
    "en",
    "en"
  ],
  "localeTexts": {
    "en": {
      "accept": "Accept",
      "acceptAll": "Accept all",
      "bannerDescription": "We use our own cookies and third-party cookies so that we can display this website correctly and better understand how this website is used, with a view to improving the services we offer. A decision on cookie usage permissions can be changed anytime using the cookie button that will appear after a selection has been made on this banner.",
      "bannerTitle": "Cookies",
      "close": "Close",
      "cookiesFunctional": "Functional cookies",
      "cookiesNecessary": "Necessary cookies",
      "cookiesOptional": "Optional cookies",
      "decline": "Decline",
      "declineAll": "Decline all",
      "here": "here",
      "iframeBlocked": "To see this, please enable functional cookies",
      "manageCookies": "Learn more and customize",
      "save": "Save",
      "settingsUnsaved": "You have unsaved settings"
    }
  }
} as ModuleOptions