import 'vuetify/styles'
import { createVuetify, type ThemeDefinition } from 'vuetify'
import { md3 } from 'vuetify/blueprints'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { VDataTable } from 'vuetify/components/VDataTable'

const dark: ThemeDefinition = {
  dark: true,
  colors: {
    background: '#090819',
    'on-background': '#ffffff',
    primary: '#fc619d',
    surface: '#141028',
    'surface-variant': '#251345',
    'on-surface-variant': '#ffffff',
    'on-primary': '#000000',

    'awesome-1': '#fc619d',
    'on-awesome-1': '#000000',
    'awesome-2': '#ff9adf',
    'on-awesome-2': '#000000',
    'awesome-3': '#ffd4ff',
    'on-awesome-3': '#000000',
  },
}

const light: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    'on-background': '#000000',
    primary: '#fc619d',
    // surface: '#ffffff',
    'surface-variant': '#f9f7fc',
    'on-surface-variant': '#000000',
    'on-primary': '#ffffff',

    'awesome-1': '#fc619d',
    'on-awesome-1': '#ffffff',
    'awesome-2': '#ff9adf',
    'on-awesome-2': '#000000',
    'awesome-3': '#ffd4ff',
    'on-awesome-3': '#000000',
  },
}

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    components: {
      VDataTable,
    },
    defaults: {
      VBtn: {
        rounded: 'none',
      },
      VAlert: {
        variant: 'tonal',
      },
      VCard: {
        rounded: 'none',
        variant: 'elevated',
      },
    },
    ssr: true,
    blueprint: md3,
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
    theme: {
      defaultTheme: 'light',
      themes: {
        dark,
        light,
      },
    },
  })

  nuxtApp.vueApp.use(vuetify)
})
