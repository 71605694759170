<template>
  <v-btn
    v-show="!store.isAuthenticated && !store.userLoading"
    v-bind="$attrs"
    @click.capture.stop="store.showSignUpDialog"
  >
    <slot> Sign up </slot>
  </v-btn>
</template>

<script setup>
import { useStore } from '@/composables/store'
const store = useStore()
</script>
