import { useStore } from '@/composables/store'

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) {
    // when analysis route is empty, just redirect to home page
    if (
      to.name === 'insights-websites-top-tech-stack' &&
      to.query.num_of_days &&
      to.query.num_of_days !== '28'
    ) {
      return navigateTo({
        ...to,
        query: { ...to.query, num_of_days: undefined },
      })
    } else if (
      to.name === 'insights-technologies-awesome' &&
      to.query.num_of_days &&
      to.query.num_of_days !== '28'
    ) {
      return navigateTo({
        ...to,
        query: { ...to.query, num_of_days: undefined },
      })
    } else if (
      to.name === 'insights-technologies-most-used' &&
      to.query.num_of_days &&
      to.query.num_of_days !== '28'
    ) {
      return navigateTo({
        ...to,
        query: { ...to.query, num_of_days: undefined },
      })
    }
  }
})
