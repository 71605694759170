<template>
  <div class="d-flex align-center">
    <nuxt-link
      :aria-label="$t('BackToHomeButton.text')"
      class="mr-2"
      style="width: 32px; height: 22px"
      to="/"
    >
      <app-icon :size="32"></app-icon>
    </nuxt-link>
    <nuxt-link
      tag="div"
      to="/"
      style="
        text-decoration: none;
        font-size: 22px;
        cursor: pointer;
        color: inherit;
      "
      class="font-weight-medium app-logo-text text-none"
    >
      AwesomeTechStack
      <!--<v-chip class="beta-chip" color="primary" size="x-small" label>
        BETA
      </v-chip>-->
    </nuxt-link>
  </div>
</template>
