<template>
  <v-btn
    v-bind="$attrs"
    :loading="store.userLoading"
    variant="text"
    @click="store.signOut"
  >
    Sign out
  </v-btn>
</template>
<script setup>
import { useStore } from '@/composables/store'
const store = useStore()
</script>
