<template>
  <v-navigation-drawer
    v-model="drawer"
    clipped
    fixed
    app
    disable-resize-watcher
    temporary
    location="right"
  >
    <v-list v-if="store.isAuthenticated">
      <v-list-item
        v-if="$auth && $auth.currentUser && $auth.currentUser.email"
        title="Signed in as"
        :subtitle="$auth.currentUser.email"
      ></v-list-item>
      <!-- authentication -->
    </v-list>
    <template v-if="store.user && store.user.plan">
      <user-billing-info-list></user-billing-info-list>
    </template>
    <template v-if="store.user && store.user.plan === 'free'">
      <need-more-analysis-text class="px-4 my-4"></need-more-analysis-text>
      <div class="pb-4 px-4">
        <select-plan-btn class="mt-2" block></select-plan-btn>
      </div>
      <v-divider></v-divider>
    </template>

    <!-- authentication -->
    <div class="pa-4">
      <sign-up-btn outlined color="primary" text block></sign-up-btn>
      <sign-in-btn text outlined block class="mt-2"></sign-in-btn>

      <sign-out-btn
        v-if="store.isAuthenticated || store.userLoading"
        outlined
        text
        block
      ></sign-out-btn>
    </div>
    <v-divider></v-divider>

    <!-- Navigation items -->
    <template
      v-for="({ text, links, to }, index) in store.navigation"
      :key="`navigation-group-${index}`"
    >
      <v-list dense nav>
        <v-list-item
          v-if="!links"
          :key="`navigation-drawer-${text}-${index}`"
          link
          :to="to"
          router
          exact
          color="primary"
          :title="text"
        >
          <!-- Content for single-item navigation -->
        </v-list-item>

        <v-list-subheader
          v-if="links"
          :key="`navigation-drawer-subheader-${text}-${index}`"
        >
          {{ text }}
        </v-list-subheader>

        <template v-if="links">
          <v-list-item
            v-for="(link, linkIndex) in filteredLinks(links)"
            :key="`navigation-drawer-link-${text}-${linkIndex}`"
            link
            :to="link.to"
            router
            exact
            color="primary"
            :title="link.text"
          >
            <!-- Content for link -->
          </v-list-item>
        </template>
      </v-list>

      <v-divider></v-divider>
    </template>

    <!-- User list section -->
    <template v-if="showUserList">
      <v-list dense nav>
        <!-- User links -->
        <template
          v-for="({ text, icon, to, show = true }, index) in store.userLinks"
          :key="`userLinks-${text}-${index}`"
        >
          <v-list-item
            :icon="icon"
            :title="text"
            v-show="show"
            :to="to"
          ></v-list-item>
        </template>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script setup>
import { mdiClose, mdiLogoutVariant } from '@mdi/js'
import { useStore } from '@/composables/store'

const store = useStore()
const { $auth } = useNuxtApp()

const mdi = { mdiClose, mdiLogoutVariant }

const drawer = computed({
  get: () => store.drawer,
  set: (val) => store.setDrawer(val),
})

const showUserList = computed(() => {
  return store.userLinks.some(({ show }) => show)
})

const filteredLinks = (links) => {
  return links.filter((link) => link.showInAppBar !== false)
}
</script>
