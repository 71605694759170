import number from '@/utils/numberFilter'
import currency from '@/utils/currencyFilter'
import { dateFormat } from '~/utils/dateFormatFilter'
import upperCase from 'lodash.uppercase'

export default defineNuxtPlugin((nuxtApp) => {
  const filters = {
    numberFilter: number,
    currencyFilter: currency,
    dateFormatFilter: dateFormat,
    upperCaseFilter: upperCase,
  }

  // Register filters globally
  for (const [name, filterFunction] of Object.entries(filters)) {
    nuxtApp.provide(name, filterFunction)
  }
})
