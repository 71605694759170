<template>
  <v-menu :key="`menu-user`" bottom offset-y centered>
    <template v-slot:activator="{ props }">
      <v-btn
        v-if="store.isAuthenticated && $auth.currentUser"
        text
        v-bind="props"
      >
        <v-icon start>{{ mdiAccount }}</v-icon>
        <span
          class="d-none d-xl-block text-none"
          style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
        >
          {{ $auth.currentUser.email }}
        </span>
        <v-icon size="small" right>{{ mdiChevronDown }}</v-icon>
      </v-btn>
      <v-btn
        v-else
        :icon="mdiAccount"
        :loading="store.userLoading"
        v-bind="props"
      ></v-btn>
    </template>

    <v-list nav>
      <v-list-subheader>{{
        $auth.currentUser.displayName || $auth.currentUser.email
      }}</v-list-subheader>
      <v-list-item
        v-for="({ to, icon, text, show = true }, index) in store.userLinks"
        :key="'user-menu-' + index"
        v-show="show"
        :to="to"
        :exact="exact"
        :title="text"
      >
        <template v-slot:prepend>
          <v-icon :icon="icon"></v-icon>
        </template>
      </v-list-item>
      <v-list-item
        v-if="store.isAuthenticated"
        @click="store.signOut"
        :to="to"
        :title="$t('UserMenu.logoutText')"
        :key="text"
      >
        <template v-slot:prepend>
          <v-icon :icon="mdiLogoutVariant"></v-icon>
        </template>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>
import { useStore } from '@/composables/store' // Update the import path to your Pinia store
import { mdiAccount, mdiChevronDown, mdiLogoutVariant } from '@mdi/js'

const { $auth } = useNuxtApp()
const store = useStore()
</script>
