<template>
  <p class="text-caption" v-if="store.user && store.user.usage">
    {{ $t('NeedMoreAnalysisText.line1') }}<br />
    {{
      $t('NeedMoreAnalysisText.line2', {
        limit: store.user.usage.limit,
      })
    }}
  </p>
</template>

<script setup>
import { useStore } from '@/composables/store'
const store = useStore()
const route = useRoute()
</script>