import validate from "/workspace/run/nuxt-service/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/workspace/run/nuxt-service/middleware/redirectTrailingSlash.global.ts";
import router_45global from "/workspace/run/nuxt-service/middleware/router.global.ts";
import unauthenticated_45global from "/workspace/run/nuxt-service/middleware/unauthenticated.global.ts";
import manifest_45route_45rule from "/workspace/run/nuxt-service/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  router_45global,
  unauthenticated_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}