export function dateFormat(date, formatString = 'DD-MMM-YYYY, h:mm A') {
  if (date instanceof Date && !isNaN(date.valueOf())) {
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }

    return new Intl.DateTimeFormat('en-US', options).format(date)
  } else {
    return ''
  }
}
