<template>
  <v-btn
    v-show="!store.isAuthenticated && !store.userLoading"
    v-bind="$attrs"
    @click="store.showSignInDialog"
  >
    {{ $t('SignInBtn.signIn') }}
  </v-btn>
</template>
<script setup>
import { useStore } from '@/composables/store'
const store = useStore()
</script>
