export default defineNuxtPlugin((nuxtApp) => {
  const { grantConsent, revokeConsent } = useGtag()
  const {
    cookiesEnabled,
    cookiesEnabledIds,
    isConsentGiven,
    isModalActive,
    moduleOptions,
  } = useCookieControl()

  if (cookiesEnabledIds?.value?.includes('google-analytics')) {
    grantConsent()
  } else {
    revokeConsent()
  }
})
