<template>
  <div ref="carbonads"></div>
</template>

<script setup>
const carbonads = ref(null)
const route = useRoute()

const init = () => {
  if (window._bsa) {
    return scriptLoaded()
  }

  const script = document.createElement('script')
  script.setAttribute('type', 'text/javascript')
  carbonads.value.appendChild(script)
  script.onload = scriptLoaded
  script.setAttribute('src', '//m.servedby-buysellads.com/monetization.js')
}

const scriptLoaded = () => {
  try {
    // window._bsa.init('stickybox', 'CEBIC27J', 'placement:awesometechstackcom')
    // window._bsa.init('fancybar', 'CK7DV5QM', 'placement:awesometechstackcom')
  } catch (err) {
    console.error(err)
  }
}

const reload = () => {
  try {
    if (window._bsa) {
      // window._bsa.reload('#_stickybox_')
      // window._bsa.reload('#_fancybar_')
    }
  } catch (err) {
    console.error(err)
  }
}

watch(route, () => {
  reload()
})

onMounted(() => {
  init()
})
</script>