<template>
  <v-list>
    <v-list-item v-if="store.user && store.user.plan">
      <v-list-item-title>
        <span class="text-primary">
          {{ store.user.plan }}
        </span>
      </v-list-item-title>

      <v-list-item-subtitle>
        {{
          $t(`UserBillingInfo.analysisLimit`, {
            limit:
              store.user.plan === 'paid'
                ? $t(`UserBillingInfo.unlimited`)
                : '10 free analysis / month',
          })
        }}
      </v-list-item-subtitle>
    </v-list-item>
  </v-list>
</template>

<script setup>
import { useStore } from '@/composables/store'
const store = useStore()
</script>
